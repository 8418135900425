export const PRODUCT_EXPIRATION_DAY_LIMIT = 30;

export const getProfileApiUrl = () => {
	return false;
};

export const FIRST_MERCHANDISING_POSITION = 0;
export const SECOND_MERCHANDISING_POSITION = 3;
export const THIRD_MERCHANDISING_POSITION = 7;
export const FOURTH_MERCHANDISING_POSITION = 11;

export const OAUTH_PARAMS = {};
